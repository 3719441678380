import "regenerator-runtime/runtime";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { isValid } from "kennitala";
import FormTextareaWithWordCounter from "./textarea_wordcount";
import InfoPopup from "./infopopup";

const Vorform = ({language}) => {

    const [isSubmitted, setIsSubmitted] = useState(false);

    // Heimsmarkmid actions
    const objectives = [
        gettext("Jafnrétti kynjanna"),
        gettext("Hreint vatn og hreinlætisaðstaða"),
        gettext("Sjálfbær orka"),
        gettext("Ábyrg neysla og framleiðsla"),
        gettext("Aðgerðir í loftlagsmálum"),
    ];

    // Type of grant
    const [selectedGrantType, setSelectedGrantType] = useState('');
    const handleGrantTypeChange = (event) => {
        setSelectedGrantType(event.target.value);
    };
  
    // Form settings, default values for FieldArrays
    const { register, handleSubmit, control, setError, watch, formState: { errors } } = useForm({
        defaultValues: {
            namsferill: [
                {
                    profgrada: '',
                    utskriftarar: '',
                    haskoliOgSvidDeild: '',
                },
            ],
            samstarfsadili: [
                {
                    nafn: '',
                    netfang: '',
                    stofnun: '',
                }
            ],
            adrir_styrkir: [
                {
                    provider: '',
                    period: '',
                    amount: '',
                }
            ]
        },
    });
    
    // Main heimsmarkmid
    const [selectedHeimsmarkmid, setSelectedHeimsmarkmid] = useState("");
    const watchedFieldValue = watch("helsta_heimsmarkmid");
    const verkefni = watch("verkefni");
    const is_patentable = watch('is_patentable');
    const heildarkostnadur = watch('heildarkostnadur');
    const applicant_type = watch('applicant_type');

    useEffect(() => {
        if (watchedFieldValue) {
            setSelectedHeimsmarkmid(watchedFieldValue);
        }
    }, [watchedFieldValue]);

    // Instanciate Bootstrap popover
    useEffect(() => {
        setTimeout(() => $('[data-toggle="popover"]').popover(), 500);
    }, [selectedGrantType, applicant_type]);    

    // FieldArrays
    const { fields: namsferillFields, append: appendNamsferill, remove: removeNamsferill } = useFieldArray({
        control,
        name: "namsferill",
    });
    const { fields: grantFields, append: grantAppend, remove: grantRemove } = useFieldArray({
        control,
        name: "adrir_styrkir",
    });
    const { fields: samstarfsadiliFields, append: samstarfsadiliAppend, remove: samstarfsadiliRemove } = useFieldArray({
        control,
        name: "samstarfsadili",
    });

    // Base64 encode files before uploading
    const onSubmit = async (data) => {
        const fileToBase64 = async (file) => {
            return new Promise((resolve, reject) => {
                if (!file) {
                    resolve(null);
                    return;
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        const encodedFiles = await Promise.all(
            ["student_cv", "supervisor_experience", "confirmed_school_attendance"].map(async (key) => {
                if (!data[key]) {
                    return { key, base64File: null };
                }
                const base64File = await fileToBase64(data[key][0]); // Access the first file in the FileList
                return { key, base64File };
            })
        );

        const jsonData = {
            language: language,
            ...data,
            ...Object.fromEntries(encodedFiles.map(({ key, base64File }) => [key, base64File])),
        };

        try {
            const response = await fetch("/api/applications/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(jsonData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}`);
            }

            const responseData = await response.json();
            setIsSubmitted(true);

        } catch (error) {
            console.error("Error submitting the form:", error);
        }
    };

    return (
        <>
            {isSubmitted ? (
                <p className="text-center mt-5"><strong>{gettext('Takk fyrir að sækja um í VOR. Umsókn þín hefur verið móttekin.')}</strong></p>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="form-group col-6">
                            <label className="label-strong required" htmlFor="tegund_styrks">{gettext('Tegund styrks')}</label>
                            <select
                                name="tegund_styrks"
                                className="form-control"
                                {...register("tegund_styrks", { required: true })}
                                onChange={handleGrantTypeChange}
                            >
                                <option value="">{gettext('-- Veldu tegund styrks --')}</option>
                                <option value="namsstyrkur">{gettext('Námsstyrkur')}</option>
                                <option value="verkefnastyrkur">{gettext('Verkefnastyrkur')}</option>
                            </select>
                            {errors.tegund_styrks && (
                                <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                            )}
                        </div>
                    </div>
                    {selectedGrantType === 'namsstyrkur' && (
                        <>
                            <label className="label-strong" htmlFor="namsferill">{gettext('Upplýsingar um nemanda')}</label>
                            <div className="row">

                                <div className="form-group col-6">
                                    <label className="required" htmlFor="nafn_nemanda">{gettext('Nafn')}</label>
                                    <input
                                        type="text"
                                        name="nafn_nemanda"
                                        className="form-control"
                                        {...register('nafn_nemanda', { required: true })}
                                    />
                                    {errors.nafn_nemanda && (
                                        <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                    )}
                                </div>

                                <div className="form-group col-3">
                                    <label htmlFor="kennitala_nemanda">{gettext('Kennitala')}</label>
                                    <input
                                        type="text"
                                        name="kennitala_nemanda"
                                        className="form-control"
                                        {...register("kennitala_nemanda", {
                                            validate: (value) => {
                                                if(value.length === 0) return true;
                                                return isValid(value) || gettext('Vinsamlegast sláið inn gilda kennitölu');
                                            }
                                        })}
                                    />
                                    {errors.kennitala_nemanda && (
                                        <small className="text-danger">{errors.kennitala_nemanda.message}</small>
                                    )}
                                </div>

                                <div className="form-group col-3">
                                    <label htmlFor="fornafn_nemanda">{gettext('Fornafn')}</label>
                                    <InfoPopup text={gettext('Við viljum sýna virðingu fyrir kynvitund umsækjenda og nota rétt persónufornöfn í samskiptum. Vinsamlegast veljið það fornafn sem best á við. Athugaðu að þessi reitur er valkvæður.')} />
                                    <select
                                        name="fornafn_nemanda"
                                        className="form-control"
                                        {...register("fornafn_nemanda")}
                                    >
                                        <option value="">{gettext('-- Veldu fornafn --')}</option>
                                        <option value="hún">{gettext('Hún')}</option>
                                        <option value="hann">{gettext('Hann')}</option>
                                        <option value="hán">{gettext('Hán')}</option>
                                        <option value="annad">{gettext('Annað')}</option>
                                    </select>
                                </div>

                            </div>
                            <div className="row">

                                <div className="form-group col-6">
                                    <label className="required" htmlFor="netfang_nemanda">{gettext('Netfang')}</label>
                                    <input
                                        type="email"
                                        name="netfang_nemanda"
                                        className="form-control"
                                        {...register("netfang_nemanda", {
                                            required: gettext('Fylla þarf út þennan reit'),
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: gettext('Netfangið er ekki á réttu sniði'),
                                            },
                                        })}
                                    />
                                    {errors.netfang_nemanda && (
                                        <small className="text-danger">{errors.netfang_nemanda.message}</small>
                                    )}
                                </div>

                                <div className="form-group col-6">
                                    <label htmlFor="simi_nemanda">{gettext('Sími')}</label>
                                    <input
                                        type="tel"
                                        name="simi_nemanda"
                                        className="form-control"
                                        {...register("simi_nemanda")}
                                    />
                                    {errors.simi_nemanda && (
                                        <small className="text-danger">{errors.simi_nemanda.message}</small>
                                    )}
                                </div>

                            </div>

                            <div className="row">
                                <div className="form-group col">
                                    <div>
                                        <label className="label-strong required" htmlFor="verkefni">{gettext('Til hvaða verkefnis er sótt um styrk?')}</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            id="lokaverkefni"
                                            type="radio"
                                            name="verkefni"
                                            value="lokaverkefni"
                                            className="form-check-input"
                                            {...register("verkefni", { required: true })}
                                        />
                                        <label className="form-check-label" htmlFor="lokaverkefni">
                                            {gettext('Lokaverkefni í tæknifræði')}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            id="meistaraverkefni"
                                            type="radio"
                                            name="verkefni"
                                            value="meistaraverkefni"
                                            className="form-check-input"
                                            {...register("verkefni", { required: true })}
                                        />
                                        <label className="form-check-label" htmlFor="meistaraverkefni">
                                            {gettext('Meistaraverkefni')}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            id="doktorsverkefni"
                                            type="radio"
                                            name="verkefni"
                                            value="doktorsverkefni"
                                            className="form-check-input"
                                            {...register("verkefni", { required: true })}
                                        />
                                        <label className="form-check-label" htmlFor="doktorsverkefni">
                                            {gettext('Doktorsverkefni')}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="label-strong required" htmlFor="haskoli">{gettext('Við hvaða háskóla og deild verður námið stundað?')}</label>
                                <input
                                    type="text"
                                    name="haskoli"
                                    className="form-control"
                                    {...register("haskoli", { required: true })}
                                />
                                {errors.haskoli && <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>}
                            </div>
                            <div className="form-group">
                                <label className="label-strong" htmlFor="namsferill">{gettext('Námsferill nemanda')}</label>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>{gettext('Prófgráða')}</th>
                                            <th>{gettext('Útskriftarár')}</th>
                                            <th>{gettext('Háskóli og svið/deild')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {namsferillFields.map((field, index) => (
                                            <tr key={field.id}>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name={`namsferill[${index}].profgrada`}
                                                        className="form-control"
                                                        {...register(`namsferill[${index}].profgrada`)}
                                                    />
                                                    {errors.namsferill && errors.namsferill[index] && errors.namsferill[index].profgrada && (
                                                        <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                                    )}
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name={`namsferill[${index}].utskriftarar`}
                                                        className="form-control"
                                                        {...register(`namsferill[${index}].utskriftarar`)}
                                                    />
                                                    {errors.namsferill && errors.namsferill[index] && errors.namsferill[index].utskriftarar && (
                                                        <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                                    )}
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name={`namsferill[${index}].haskoliOgSvidDeild`}
                                                        className="form-control"
                                                        {...register(`namsferill[${index}].haskoliOgSvidDeild`)}
                                                    />
                                                    {errors.namsferill && errors.namsferill[index] && errors.namsferill[index].haskoliOgSvidDeild && (
                                                        <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                                    )}
                                                </td>
                                                <td>
                                                    <div onClick={() => removeNamsferill(index)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                                                            <g fill="none" fillRule="evenodd">
                                                                <circle cx="10" cy="10" r="10" fill="#DEDEDE" />
                                                                <path fill="#979797" fillRule="nonzero" d="m13.778 6 .707.707-.353.354-3.183 3.181 3.536 3.536-.707.707-3.536-3.536-3.181 3.183-.354.353L6 13.778l.354-.353 3.181-3.183L6 6.707 6.707 6l3.535 3.535 3.183-3.181.353-.354Z" />
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <button type="button" className="btn btn-primary" onClick={() => appendNamsferill({})}>
                                    {gettext('Bæta við línu')}
                                </button>
                            </div>
                            <label className="label-strong">{gettext('Aðalleiðbeinandi')}</label>
                            <InfoPopup text={gettext('Nauðsynlegt er að vera komin með verkefni og aðalleiðbeinanda áður en sótt er um námsstyrk.')} />
                            <div className="row">
                                <div className="form-group col-6">
                                    <label className="required" htmlFor="nafn_adalleidbeinanda">{gettext('Nafn aðalleiðbeinanda')}</label>
                                    <input
                                        id="nafn_adalleidbeinanda"
                                        type="text"
                                        name="nafn_adalleidbeinanda"
                                        className="form-control"
                                        {...register("nafn_adalleidbeinanda", { required: true })}
                                    />
                                    {errors.nafn_adalleidbeinanda && (
                                        <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                    )}
                                </div>
                                <div className="form-group col-6">
                                    <label className="required" htmlFor="netfang_adalleidbeinanda">{gettext('Netfang aðalleiðbeinanda')}</label>
                                    <input
                                        id="netfang_adalleidbeinanda"
                                        type="text"
                                        name="netfang_adalleidbeinanda"
                                        className="form-control"
                                        {...register("netfang_adalleidbeinanda", {
                                            required: gettext('Fylla þarf út þennan reit'),
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: gettext('Netfangið er ekki á réttu sniði'),
                                            },
                                        })}
                                    />
                                    {errors.netfang_adalleidbeinanda && (
                                        <small className="text-danger">{errors.netfang_adalleidbeinanda.message}</small>
                                    )}
                                </div>
                                <div className="form-group col-6">
                                    <label className="required" htmlFor="stofnun_adalleidbeinanda">{gettext('Stofnun aðalleiðbeinanda')}</label>
                                    <input
                                        id="stofnun_adalleidbeinanda"
                                        type="text"
                                        name="stofnun_adalleidbeinanda"
                                        className="form-control"
                                        {...register("stofnun_adalleidbeinanda", { required: true })}
                                    />
                                    {errors.stofnun_adalleidbeinanda && (
                                        <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                    )}
                                </div>
                                <div className="form-group col-6">
                                    <label className="required" htmlFor="fraedasvid_adalleidbeinanda">{gettext('Fræðasvið aðalleiðbeinanda')}</label>
                                    <input
                                        id="fraedasvid_adalleidbeinanda"
                                        type="text"
                                        name="fraedasvid_adalleidbeinanda"
                                        className="form-control"
                                        {...register("fraedasvid_adalleidbeinanda", { required: true })}
                                    />
                                    {errors.fraedasvid_adalleidbeinanda && (
                                        <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {selectedGrantType === 'verkefnastyrkur' && (
                        <>
                            <div className="form-group">
                                <label className="required">{gettext('Ertu að sækja um sem einstaklingur (með samstarfsaðilum ef við á) eða fyrir hönd fyrirtækis? (hakið við eitt)')}</label>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        name="applicant_type"
                                        id="individual"
                                        value="individual"
                                        className="form-check-input"
                                        {...register('applicant_type', { required: true })}
                                    />
                                    <label htmlFor="individual">{gettext('Einstaklingur')}</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        name="applicant_type"
                                        id="company"
                                        value="company"
                                        className="form-check-input"
                                        {...register('applicant_type', { required: true })}
                                    />
                                    <label htmlFor="company">{gettext('Fyrirtæki')}</label>
                                </div>
                                {errors.applicant_type && <small className="text-danger">{gettext('Velja þarf annað hvort')}</small>}
                            </div>
                            {applicant_type === 'individual' ? (
                                <>
                                    <label className="label-strong">{gettext('Aðalumsækjandi')}</label>
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <label className="required" htmlFor="nafn_adalumsaekjanda">{gettext('Nafn')}</label>
                                            <input
                                                type="text"
                                                name="nafn_adalumsaekjanda"
                                                className="form-control"
                                                {...register('nafn_adalumsaekjanda', { required: true })}
                                            />
                                            {errors.nafn_adalumsaekjanda && (
                                                <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                            )}
                                        </div>
                                        <div className="form-group col-6">
                                            <label className="required" htmlFor="starfsheiti_adalumsaekjanda">{gettext('Starfsheiti')}</label>
                                            <input
                                                type="text"
                                                name="starfsheiti_adalumsaekjanda"
                                                className="form-control"
                                                {...register('starfsheiti_adalumsaekjanda', { required: true })}
                                            />
                                            {errors.starfsheiti_adalumsaekjanda && (
                                                <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                            )}
                                        </div>
                                        <div className="form-group col-6">
                                            <label className="required" htmlFor="stofnun_adalumsaekjanda">{gettext('Stofnun (fyrirtæki, háskóli, einyrki)')}</label>
                                            <input
                                                type="text"
                                                name="stofnun_adalumsaekjanda"
                                                className="form-control"
                                                {...register('stofnun_adalumsaekjanda', { required: true })}
                                            />
                                            {errors.stofnun_adalumsaekjanda && (
                                                <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                            )}
                                        </div>
                                        <div className="form-group col-3">
                                            <label htmlFor="kennitala_adalumsaekjanda">{gettext('Kennitala')}</label>
                                            <input
                                                type="text"
                                                name="kennitala_adalumsaekjanda"
                                                className="form-control"
                                                {...register('kennitala_adalumsaekjanda', {
                                                    validate: (value) => {
                                                        if(value.length === 0) return true;
                                                        return isValid(value) || gettext('Vinsamlegast sláið inn gilda kennitölu');
                                                    }
                                                })}
                                            />
                                            {errors.kennitala_adalumsaekjanda && (
                                                <small className="text-danger">{errors.kennitala_adalumsaekjanda.message}</small>
                                            )}
                                        </div>
                                        <div className="form-group col-3">
                                            <label htmlFor="fornafn_adalumsaekjanda">{gettext('Fornafn')}</label>
                                            <InfoPopup text={gettext('Við viljum sýna virðingu fyrir kynvitund umsækjenda og nota rétt persónufornöfn í samskiptum. Vinsamlegast veljið það fornafn sem best á við. Athugaðu að þessi reitur er valkvæður.')} />
                                            <select
                                                name="fornafn_adalumsaekjanda"
                                                className="form-control"
                                                {...register("fornafn_adalumsaekjanda")}
                                            >
                                                <option value="">{gettext('-- Veldu fornafn --')}</option>
                                                <option value="hún">{gettext('Hún')}</option>
                                                <option value="hann">{gettext('Hann')}</option>
                                                <option value="hán">{gettext('Hán')}</option>
                                                <option value="annad">{gettext('Annað')}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <label className="required" htmlFor="netfang">{gettext('Netfang')}</label>
                                            <input
                                                type="email"
                                                name="netfang"
                                                className="form-control"
                                                {...register("netfang_adalumsaekjanda", {
                                                    required: gettext('Fylla þarf út þennan reit'),
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: gettext('Netfangið er ekki á réttu sniði'),
                                                    },
                                                })}
                                            />
                                            {errors.netfang && (
                                                <small className="text-danger">{errors.netfang.message}</small>
                                            )}
                                        </div>
                                        <div className="form-group col-6">
                                            <label htmlFor="simi">{gettext('Sími')}</label>
                                            <input
                                                type="tel"
                                                name="simi"
                                                className="form-control"
                                                {...register("simi_adalumsaekjanda")}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <label className="label-strong">{gettext('Fyrirtæki')}</label>
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <label className="required" htmlFor="nafn_fyrirtaekis">{gettext('Nafn')}</label>
                                            <input
                                                type="text"
                                                name="nafn_fyrirtaekis"
                                                className="form-control"
                                                {...register('nafn_fyrirtaekis', { required: true })}
                                            />
                                            {errors.nafn_fyrirtaekis && (
                                                <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                            )}
                                        </div>
                                        <div className="form-group col-6">
                                            <label htmlFor="kennitala_fyrirtaekis">{gettext('Kennitala')}</label>
                                            <input
                                                type="text"
                                                name="kennitala_fyrirtaekis"
                                                className="form-control"
                                                {...register('kennitala_fyrirtaekis', {
                                                    validate: (value) => {
                                                        if(value.length === 0) return true;
                                                        return isValid(value) || gettext('Vinsamlegast sláið inn gilda kennitölu');
                                                    }
                                                })}
                                            />
                                            {errors.kennitala_fyrirtaekis && (
                                                <small className="text-danger">{errors.kennitala_fyrirtaekis.message}</small>
                                            )}
                                        </div>
                                        <div className="form-group col-6">
                                            <label className="required" htmlFor="nafn_tengilids">{gettext('Nafn tengiliðs')}</label>
                                            <input
                                                type="text"
                                                name="nafn_tengilids"
                                                className="form-control"
                                                {...register('nafn_tengilids', { required: true })}
                                            />
                                            {errors.nafn_tengilids && (
                                                <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                            )}
                                        </div>
                                        <div className="form-group col-6">
                                            <label className="required" htmlFor="starfsheiti_tengilids">{gettext('Starfsheiti tengiliðs')}</label>
                                            <input
                                                type="text"
                                                name="starfsheiti_tengilids"
                                                className="form-control"
                                                {...register('starfsheiti_tengilids', { required: true })}
                                            />
                                            {errors.starfsheiti_tengilids && (
                                                <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                            )}
                                        </div>
                                        <div className="form-group col-6">
                                            <label htmlFor="kennitala_tengilids">{gettext('Kennitala tengiliðs')}</label>
                                            <input
                                                type="text"
                                                name="kennitala_tengilids"
                                                className="form-control"
                                                {...register('kennitala_tengilids', {
                                                    validate: (value) => {
                                                        if(value.length === 0) return true;
                                                        return isValid(value) || gettext('Vinsamlegast sláið inn gilda kennitölu');
                                                    }
                                                })}
                                            />
                                            {errors.kennitala_tengilids && (
                                                <small className="text-danger">{errors.kennitala_tengilids.message}</small>
                                            )}
                                        </div>
                                        <div className="form-group col-6">
                                            <label htmlFor="fornafn_tengilids">{gettext('Fornafn tengiliðs')}</label>
                                            <InfoPopup text={gettext('Við viljum sýna virðingu fyrir kynvitund umsækjenda og nota rétt persónufornöfn í samskiptum. Vinsamlegast veljið það fornafn sem best á við. Athugaðu að þessi reitur er valkvæður.')} />
                                            <select
                                                name="fornafn_tengilids"
                                                className="form-control"
                                                {...register("fornafn_tengilids")}
                                            >
                                                <option value="">{gettext('-- Veldu fornafn --')}</option>
                                                <option value="hún">{gettext('Hún')}</option>
                                                <option value="hann">{gettext('Hann')}</option>
                                                <option value="hán">{gettext('Hán')}</option>
                                                <option value="annad">{gettext('Annað')}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <label className="required" htmlFor="netfang">{gettext('Netfang tengiliðs')}</label>
                                            <input
                                                type="email"
                                                name="netfang"
                                                className="form-control"
                                                {...register("netfang_tengilids", {
                                                    required: gettext('Fylla þarf út þennan reit'),
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: gettext('Netfangið er ekki á réttu sniði'),
                                                    },
                                                })}
                                            />
                                            {errors.netfang && (
                                                <small className="text-danger">{errors.netfang_tengilids.message}</small>
                                            )}
                                        </div>
                                        <div className="form-group col-6">
                                            <label htmlFor="simi">{gettext('Sími')}</label>
                                            <input
                                                type="tel"
                                                name="simi"
                                                className="form-control"
                                                {...register("simi_tengilids")}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {selectedGrantType &&
                        <>
                            <div className="form-group">
                                {selectedGrantType === 'namsstyrkur' ?
                                    <label className="label-strong" htmlFor="samstarfsadili">{gettext('Meðleiðbeinendur og aðrir samstarfsaðilar (fyllið út ef við á)')}</label>
                                    :
                                    <label className="label-strong" htmlFor="samstarfsadili">{gettext('Samstarfsaðilar (fyllið út ef við á)')}</label>
                                }
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>{gettext('Nafn')}</th>
                                            <th>{gettext('Netfang')}</th>
                                            <th>{gettext('Stofnun / fyrirtæki')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {samstarfsadiliFields.map((field, index) => (
                                            <tr key={field.id}>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name={`samstarfsadili[${index}].nafn`}
                                                        className="form-control"
                                                        {...register(`samstarfsadili[${index}].nafn`)}
                                                    />
                                                    {errors.samstarfsadili && errors.samstarfsadili[index] && errors.samstarfsadili[index].nafn && (
                                                        <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                                    )}
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name={`samstarfsadili[${index}].netfang`}
                                                        className="form-control"
                                                        {...register(`samstarfsadili[${index}].netfang`)}
                                                    />
                                                    {errors.samstarfsadili && errors.samstarfsadili[index] && errors.samstarfsadili[index].netfang && (
                                                        <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                                    )}
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name={`samstarfsadili[${index}].stofnun`}
                                                        className="form-control"
                                                        {...register(`samstarfsadili[${index}].stofnun`)}
                                                    />
                                                    {errors.samstarfsadili && errors.samstarfsadili[index] && errors.samstarfsadili[index].stofnun && (
                                                        <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                                    )}
                                                </td>
                                                <td>
                                                    <div type="button" onClick={() => samstarfsadiliRemove(index)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                                                            <g fill="none" fillRule="evenodd">
                                                                <circle cx="10" cy="10" r="10" fill="#DEDEDE" />
                                                                <path fill="#979797" fillRule="nonzero" d="m13.778 6 .707.707-.353.354-3.183 3.181 3.536 3.536-.707.707-3.536-3.536-3.181 3.183-.354.353L6 13.778l.354-.353 3.181-3.183L6 6.707 6.707 6l3.535 3.535 3.183-3.181.353-.354Z" />
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <button type="button" className="btn btn-primary" onClick={() => samstarfsadiliAppend({})}>
                                    {gettext('Bæta við línu')}
                                </button>
                            </div>

                            <div className="form-group">
                                <label className="label-strong required" htmlFor="heiti_verkefnis">{gettext('Heiti verkefnis')}</label>
                                <input
                                    id="heiti_verkefnis"
                                    type="text"
                                    name="heiti_verkefnis"
                                    className="form-control"
                                    {...register("heiti_verkefnis", { required: true })}
                                />
                                {errors.heiti_verkefnis && (
                                    <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="label-strong required">{gettext('Hvað af eftirfarandi heimsmarkmiðum styður verkefnið helst við?')}</label>
                                <InfoPopup text={gettext("Sjá eftirfarandi vefslóðir fyrir frekar upplýsingar um heimsmarkmiðin: <br/><ul><li><a href='https://www.or.is/um-or/heimsmarkmidin/' target='_blank'>https://www.or.is/um-or/heimsmarkmidin/</a></li><li><a href='https://www.heimsmarkmidin.is/forsida/heimsmarkmidin' target='_blank'>https://www.heimsmarkmidin.is/forsida/heimsmarkmidin</a></li></ul>")} />
                                {objectives.map((objective, index) => (
                                    <div className="form-check" key={index}>
                                        <input
                                            id={`radio-${index}`}
                                            type="radio"
                                            name="helsta_heimsmarkmid"
                                            value={objective}
                                            className="form-check-input"
                                            {...register("helsta_heimsmarkmid", { required: true })}
                                        />
                                        <label className="form-label" htmlFor={`radio-${index}`}>
                                            {objective}
                                        </label>
                                    </div>
                                ))}
                                {errors.helsta_heimsmarkmid && (
                                    <small className="text-danger">{gettext('Fylla þarf út þennan reit')}</small>
                                )}
                            </div>
                            {selectedHeimsmarkmid && (
                                <div className="form-group">
                                    <label className="label-strong">
                                        {gettext('Eru fleiri heimsmarkmið sem verkefnið styður við?')}
                                    </label>
                                    {objectives.map((objective, index) => {
                                        if (objective !== selectedHeimsmarkmid) {
                                            return (
                                                <div className="form-check" key={index}>
                                                    <input
                                                        id={`onnur_heimsmarkmid-${index}`}
                                                        type="checkbox"
                                                        name={`onnur_heimsmarkmid-${index}`}
                                                        value={objective}
                                                        className="form-check-input"
                                                        {...register(`onnur_heimsmarkmid${index}`)}
                                                    />
                                                    <label className="form-label" htmlFor={`onnur_heimsmarkmid-${index}`}>
                                                        {objective}
                                                    </label>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            )}
                            <div className="form-group">
                                <label className="label-strong required" htmlFor="heildarkostnadur">
                                    {gettext('Hver er heildarkostnaður við verkefnið (ISK)?')}
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="heildarkostnadur"
                                    {...register("heildarkostnadur", { valueAsNumber: true, required: true })}
                                />
                            </div>
                            {selectedGrantType === 'namsstyrkur' ? (
                                <div className="form-group">
                                    <label className="label-strong required" htmlFor="styrkupphaed">
                                        {gettext('Hver er styrkupphæðin sem sótt er um (ISK)?')}
                                    </label>
                                    <InfoPopup text={gettext('Athugið að styrkur getur að hámarki numið 1.250.000 kr. fyrir meistaraverkefni og lokaverkefni í tæknifræði og 5.000.000 kr. fyrir doktorsverkefni.')} />
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="styrkupphaed"
                                        {...register("styrkupphaed", {
                                            required: true,
                                            valueAsNumber: true,
                                            validate: (value) => {
                                                if(value > heildarkostnadur) {
                                                    return gettext("Styrkupphæðin má ekki vera hærri en heildarkostnaður verkefnis.");
                                                }
                                                if(verkefni === 'doktorsverkefni'){
                                                    if(value > 5000000) {
                                                        return gettext("Styrkupphæðin má ekki vera hærri en 5.000.000 kr.");
                                                    }
                                                } else if(value > 1250000) {
                                                    return gettext("Styrkupphæðin má ekki vera hærri en 1.250.000 kr.");
                                                }
                                                return true;
                                            }
                                        })}
                                    />
                                    {errors.styrkupphaed && (
                                        <small className="text-danger">{errors.styrkupphaed.message}</small>
                                    )}
                                </div>
                            ) : (
                                <div className="form-group">
                                    <label className="label-strong required" htmlFor="styrkupphaed">
                                        {gettext('Hver er styrkupphæðin sem sótt er um (ISK)?')}
                                    </label>
                                    <InfoPopup text={gettext('Athugið að styrkur getur að hámarki numið 8.000.000 kr. fyrir verkefnastyrki og verið að hámarki 70% af heildarkostnaði verkefnis.')} />
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="styrkupphaed"
                                        {...register("styrkupphaed", {
                                            required: true,
                                            valueAsNumber: true,
                                            validate: (value) => {
                                                console.log(value, heildarkostnadur)
                                                if(value > 0.7*heildarkostnadur) {
                                                    return gettext("Styrkupphæðin má ekki vera hærri en 70% af heildarkostnaði verkefnis.");
                                                }
                                                if(value > 8000000) {
                                                    return gettext("Styrkupphæðin má ekki vera hærri en 8.000.000 kr.");
                                                }
                                                return true;
                                            }
                                        })}
                                    />
                                    {errors.styrkupphaed && (
                                        <small className="text-danger">{errors.styrkupphaed.message}</small>
                                    )}
                                </div>
                            )}
                            <div className="form-group">
                                <label className="label-strong required" htmlFor="lengd_verkefnis">
                                    {gettext('Hver er tímalengd verkefnisins?')}
                                </label>
                                <InfoPopup text={gettext('Athugið að aðeins er veittur styrkur til eins árs í senn jafnvel þótt tímalengd verkefnisins sé lengri. Allir umsækjendur þurfa að sækja um á hverju ári óháð því hvort umsækjandi hafi hlotið styrk frá VOR áður.')} />
                                <select
                                    name="lengd_verkefnis"
                                    className="form-control"
                                    {...register("lengd_verkefnis", { required: true })}
                                >
                                    <option value="">{gettext('-- Veldu lengd verkefnis --')}</option>
                                    <option value="< Eitt ár">{gettext('< Eitt ár')}</option>
                                    <option value="Eitt ár">{gettext('Eitt ár')}</option>
                                    <option value="Tvö ár">{gettext('Tvö ár')}</option>
                                    <option value="Þrjú ár">{gettext('Þrjú ár')}</option>
                                    <option value="> Þrjú ár">{gettext('> Þrjú ár')}</option>
                                </select>
                                {errors.lengd_verkefnis && (
                                    <small className="text-danger">
                                        {gettext('Þessi reitur er nauðsynlegur.')}
                                    </small>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="label-strong">
                                    {gettext('Hefur verkefnið fengið aðra styrki (fyllið út ef við á)?')}
                                </label>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>{gettext('Styrkveitandi')}</th>
                                            <th>{gettext('Tímabil')}</th>
                                            <th>{gettext('Upphæð')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {grantFields.map((field, index) => (
                                            <tr key={field.id}>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...register(`adrir_styrkir[${index}].provider`)}
                                                        defaultValue={field.provider}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...register(`adrir_styrkir[${index}].period`)}
                                                        defaultValue={field.period}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        {...register(`adrir_styrkir[${index}].amount`)}
                                                        defaultValue={field.amount}
                                                    />
                                                </td>
                                                <td>
                                                    <div onClick={() => grantRemove(index)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                                                            <g fill="none" fillRule="evenodd">
                                                                <circle cx="10" cy="10" r="10" fill="#DEDEDE" />
                                                                <path fill="#979797" fillRule="nonzero" d="m13.778 6 .707.707-.353.354-3.183 3.181 3.536 3.536-.707.707-3.536-3.536-3.181 3.183-.354.353L6 13.778l.354-.353 3.181-3.183L6 6.707 6.707 6l3.535 3.535 3.183-3.181.353-.354Z" />
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() =>
                                        grantAppend({ provider: "", period: "", amount: "" })
                                    }
                                >
                                    {gettext('Bæta við línu')}
                                </button>
                            </div>
                            {selectedGrantType === 'verkefnastyrkur' && (
                                <>
                                <div className="row">
                                    <div className="form-group col">
                                        <div>
                                            <label className="label-strong required" htmlFor="is_patentable">{gettext('Er verkefnið einkaleyfishæft?')}</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                id="yes"
                                                type="radio"
                                                name="is_patentable"
                                                value="yes"
                                                className="form-check-input"
                                                {...register("is_patentable", { required: true })}
                                            />
                                            <label className="form-check-label" htmlFor="yes">
                                                {gettext('Já')}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                id="no"
                                                type="radio"
                                                name="is_patentable"
                                                value="no"
                                                className="form-check-input"
                                                {...register("is_patentable", { required: true })}
                                            />
                                            <label className="form-check-label" htmlFor="no">
                                                {gettext('Nei')}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                id="dont_know"
                                                type="radio"
                                                name="is_patentable"
                                                value="dont_know"
                                                className="form-check-input"
                                                {...register("is_patentable", { required: true })}
                                            />
                                            <label className="form-check-label" htmlFor="dont_know">
                                                {gettext('Veit ekki')}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {is_patentable === 'yes' && (
                                <div className="row">
                                    <div className="form-group col">
                                        <div>
                                            <label className="label-strong required" htmlFor="apply_patent">{gettext('Er ætlunin að sækja um einkaleyfi?')}</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                id="applyYes"
                                                type="radio"
                                                name="apply_patent"
                                                value="yes"
                                                className="form-check-input"
                                                {...register("apply_patent", { required: true })}
                                            />
                                            <label className="form-check-label" htmlFor="applyYes">
                                                {gettext('Já')}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                id="applyNo"
                                                type="radio"
                                                name="apply_patent"
                                                value="no"
                                                className="form-check-input"
                                                {...register("apply_patent", { required: true })}
                                            />
                                            <label className="form-check-label" htmlFor="applyNo">
                                                {gettext('Nei')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                )}
                                </>
                            )}
                            <FormTextareaWithWordCounter
                                label={gettext("Útdráttur")}
                                register={register("utdrattur", {
                                    required: true,
                                    validate: (value) => value.split(/\s+/).filter((word) => word !== "").length <= 200 || gettext("Mesti fjöldi orða er 200"),
                                })}
                                errors={errors.abstract}
                                maxLength={200}
                                required={true}
                                placeholder={gettext("Lýsið verkefninu í hnotskurn. Lýsingin skal henta til opinberrar birtingar.")}
                            />
                            <FormTextareaWithWordCounter
                                label={gettext("Markmið verkefnis")}
                                register={register("markmid_verkefnis", {
                                    required: true,
                                    validate: (value) => value.split(/\s+/).filter((word) => word !== "").length <= 500 || gettext("Mesti fjöldi orða er 500"),
                                })}
                                errors={errors.markmid_verkefnis}
                                maxLength={500}
                                required={true}
                                placeholder={gettext("Greinið frá markmiðum verkefnisins og þeim spurningum sem leitast verður við að svara í rannsókninni.")}
                            />
                            <FormTextareaWithWordCounter
                                label={gettext("Lýsing á verkefninu")}
                                register={register("lysing_verkefnis", {
                                    required: true,
                                    validate: (value) => value.split(/\s+/).filter((word) => word !== "").length <= 500 || gettext("Mesti fjöldi orða er 500"),
                                })}
                                errors={errors.lysingVerkefnis}
                                maxLength={500}
                                required={true}
                                placeholder={gettext("Lýsið verkefninu stuttlega, skipulagningu þess og helstu verkþáttum ef við á. Hvernig verður markmiðum verkefnins náð? Lýsið helstu aðferðum sem beita á í rannsókninni og úrvinnslu.")}
                            />
                            <FormTextareaWithWordCounter
                                label={gettext("Afrakstur verkefnis")}
                                register={register("afrakstur_verkefnis", {
                                    required: true,
                                    validate: (value) => value.split(/\s+/).filter((word) => word !== "").length <= 500 || gettext("Mesti fjöldi orða er 500"),
                                })}
                                errors={errors.afrakstur_verkefnis}
                                maxLength={500}
                                required={true}
                                placeholder={gettext("Hverju mun verkefnið skila?")}
                            />
                            <FormTextareaWithWordCounter
                                label={gettext("Nýnæmi verkefnis")}
                                register={register("nynaemi_verkefnis", {
                                    required: true,
                                    validate: (value) => value.split(/\s+/).filter((word) => word !== "").length <= 500 || gettext("Mesti fjöldi orða er 500"),
                                })}
                                errors={errors.nynaemi_verkefnis}
                                maxLength={500}
                                required={true}
                                placeholder={gettext("Hvert er nýnæmi verkefnisins?")}
                            />
                            <FormTextareaWithWordCounter
                                label={gettext("Tenging við heimsmarkmið Sameinuðu þjóðanna")}
                                info={<InfoPopup text={gettext("Sjá eftirfarandi vefslóðir fyrir frekar upplýsingar um heimsmarkmiðin: <br/><ul><li><a href='https://www.or.is/um-or/heimsmarkmidin/' target='_blank'>https://www.or.is/um-or/heimsmarkmidin/</a></li><li><a href='https://www.heimsmarkmidin.is/forsida/heimsmarkmidin' target='_blank'>https://www.heimsmarkmidin.is/forsida/heimsmarkmidin</a></li></ul>")} />}
                                register={register("sdg_tenging_verkefnis", {
                                    required: true,
                                    validate: (value) => value.split(/\s+/).filter((word) => word !== "").length <= 250 || gettext("Mesti fjöldi orða er 250"),
                                })}
                                errors={errors.sdg_tenging_verkefnis}
                                maxLength={250}
                                required={true}
                                placeholder={gettext("Hvernig tengist verkefnið þeim heimsmarkmiðum Sameinuðu þjóðanna sem Orkuveita Reykjavíkur leggur áherslu á?")}
                            />
                            <FormTextareaWithWordCounter
                                label={gettext("Tenging við starfssvið og áherslur Orkuveitu Reykjavíkur")}
                                info={<InfoPopup text={gettext("Sjá eftirfarandi vefslóðir fyrir frekari upplýsingar um stefnu og störf Orkuveitu Reykjavíkur:<br/><ul><li><a href='https://www.or.is/um-or/skipulag-og-stjornhaettir/stefnuskjol/' target='_blank'>https://www.or.is/um-or/skipulag-og-stjornhaettir/stefnuskjol/</a></li></ul>")} />}
                                register={register("or_tenging_verkefnis", {
                                    required: true,
                                    validate: (value) => value.split(/\s+/).filter((word) => word !== "").length <= 250 || gettext("Mesti fjöldi orða er 250"),
                                })}
                                errors={errors.or_tenging_verkefnis}
                                maxLength={250}
                                required={true}
                                placeholder={gettext("Hvernig tengist verkefnið starfssviði og áherslum Orkuveitu Reykjavíkur?")}
                            />
                            <FormTextareaWithWordCounter
                                label={gettext("Sundurliðuð tímaáætlun")}
                                register={register("time_schedule", {
                                    required: true,
                                    validate: (value) => value.split(/\s+/).filter((word) => word !== "").length <= 200 || gettext("Mesti fjöldi orða er 200"),
                                })}
                                errors={errors.time_schedule}
                                maxLength={200}
                                required={true}
                                placeholder={gettext("Hver er tímaáætlun verkefnisins")}
                            />
                            <FormTextareaWithWordCounter
                                label={gettext("Sundurliðuð kostnaðaráætlun")}
                                register={register("cost_schedule", {
                                    required: true,
                                    validate: (value) => value.split(/\s+/).filter((word) => word !== "").length <= 200 || gettext("Mesti fjöldi orða er 200"),
                                })}
                                errors={errors.cost_schedule}
                                maxLength={200}
                                required={true}
                                placeholder={gettext("Gerið grein fyrir áætluðum kostnaði verkefnisins (launakostnaður og annar beinn kostnaður sem tengist rannsókninni). Takið einnig fram helstu óvissuþætti sem snúa að kostnaði verkefnisins.")}
                            />
                            <FormTextareaWithWordCounter
                                label={gettext("Annað (ef við á)")}
                                register={register("additional_info", {
                                    validate: (value) => value.split(/\s+/).filter((word) => word !== "").length <= 250 || gettext("Mesti fjöldi orða er 250"),
                                })}
                                errors={errors.additional_info}
                                maxLength={250}
                                placeholder={gettext("")}
                            />
                            {selectedGrantType === 'namsstyrkur' ? (
                                <>
                                <div className="form-group">
                                    <label className="label-strong required" htmlFor="student_cv">{gettext("Námsferill og einkunnir nemanda")}</label>
                                    <input
                                        type="file"
                                        className="form-control-file"
                                        id="student_cv"
                                        accept=".pdf"
                                        {...register("student_cv", { required: true })}
                                    />
                                    {errors.student_cv && (
                                        <small className="text-danger">{gettext("Skjal er nauðsynlegt")}</small>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label className="label-strong required" htmlFor="confirmed_school_attendance">
                                        {gettext("Staðfest skólavist")}
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control-file"
                                        id="confirmed_school_attendance"
                                        accept=".pdf"
                                        {...register("confirmed_school_attendance", { required: true })}
                                    />
                                    {errors.confirmed_school_attendance && (
                                        <small className="text-danger">{gettext("Skjal er nauðsynlegt")}</small>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label className="label-strong required" htmlFor="supervisor_experience">
                                        {gettext("Reynsla og hæfni leiðbeinanda")}
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control-file"
                                        id="supervisor_experience"
                                        accept=".pdf"
                                        {...register("supervisor_experience", { required: true })}
                                    />
                                    {errors.supervisor_experience && (
                                        <small className="text-danger">{gettext("Skjal er nauðsynlegt")}</small>
                                    )}
                                </div>
                                </>
                            ) : (
                                <div className="form-group">
                                    <label className="label-strong required" htmlFor="student_cv">{gettext("Ferilskrár umsækjanda")}</label>
                                    <input
                                        type="file"
                                        className="form-control-file"
                                        id="student_cv"
                                        accept=".pdf"
                                        {...register("student_cv", { required: true })}
                                    />
                                    {errors.student_cv && (
                                        <small className="text-danger">{gettext("Skjal er nauðsynlegt")}</small>
                                    )}
                                </div>
                            )}
                            <div className="form-group form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="stadfesta_upplysingar"
                                    {...register("stadfesta_upplysingar", { required: true })}
                                />
                                <label
                                    className="form-check-label required"
                                    htmlFor="stadfesta_upplysingar"
                                >
                                    {gettext("Ég staðfesti hér með að upplýsingar í innsendum gögnum eru réttar.")}
                                </label>
                                {errors.stadfesta_upplysingar && (
                                    <small className="text-danger">{gettext("Nauðsynlegt að staðfesta")}</small>
                                )}
                            </div>
                            <div className="form-group form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="confirm_rules"
                                    {...register("confirm_rules", { required: true })}
                                />
                                <label
                                    className="form-check-label required"
                                    htmlFor="confirm_rules"
                                >
                                    {gettext("Ég staðfesti að hafa kynnt mér úthlutunarreglur sjóðsins og persónuverndarstefnu Orkuveitur Reykjavíkur.")}
                                </label>
                                {errors.confirm_rules && (
                                    <small className="text-danger">{gettext("Nauðsynlegt að staðfesta")}</small>
                                )}
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="allow_or_staff"
                                    {...register("allow_or_staff")}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="allow_or_staff"
                                >
                                    {gettext("Ég gef leyfi fyrir því að starfsmenn OR samstæðunnar megi sjá nafn og netfang aðalumsækjanda, heiti verkefnis og útdrátt, óháð því hvort ég hljóti styrk eða ekki. Samþykki getur skapað grundvöll fyrir annars konar samstarf við Orkuveitu Reykjavíkur utan VOR.")}
                                </label>
                                {errors.allow_or_staff && (
                                    <small className="text-danger">{gettext("Nauðsynlegt að staðfesta")}</small>
                                )}
                            </div>
                            <button type="submit" className="btn btn-primary btn-lg">
                                {gettext("Senda")}
                            </button>
                        </>
                    }
                </form>
            )}</>
    )
}

export default Vorform;