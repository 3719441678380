import React, { useState } from "react";

const FormTextareaWithWordCounter = ({ label, register, errors, maxLength, info, placeholder, required }) => {
  const [wordCount, setWordCount] = useState(0);

  const handleInputChange = (e) => {
    setWordCount(e.target.value.split(/\s+/).filter((word) => word !== "").length);
  };
  console.log(register)
  return (
    <div className="form-group">
      <label className={`label-strong ${required ? "required" : ""}`} htmlFor={register.name}>
        {label}
      </label>
      {info}
      <textarea
        className="form-control"
        id={register.name}
        {...register}
        onChange={handleInputChange}
        placeholder={placeholder}
      />
      <div className="d-flex justify-content-end">
        <span className="wordcount"><span className={`${wordCount > maxLength ? 'too-long' : ''}`}>{wordCount}</span> / {maxLength} {gettext('orð')}</span>
      </div>
      {errors && <small className="text-danger">{errors.message}</small>}
    </div>
  );
};

export default FormTextareaWithWordCounter